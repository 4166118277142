import axios from 'src/utils/axios';

const CustomerTypes = (queryClient) => {
  async function updateCustomerTypes(customerTypes) {
    const response = await axios.post('/api/customerTypes', customerTypes);
    return response.data;
  }

  return {
    mutationFn: async ({ data }) => {
      // to avoid clashes with our optimistic update when an offline mutation continues
      await queryClient.cancelQueries({ queryKey: ['customerTypes'] });
      return updateCustomerTypes(data);
    },
    onMutate: async (variables) => {
      const customerType = variables.data;
      await queryClient.cancelQueries(['customerTypes']);

      const previousValue = queryClient.getQueryData(['customerTypes']);
      queryClient.setQueryData(['customerTypes'], (old) => [
        ...old.filter((x) => x.id !== customerType.id),
        customerType
      ]);
      return previousValue;
    },
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(['customerTypes'], previousValue),
    onSettled: () => queryClient.invalidateQueries(['customerTypes']),
    retry: 3
  };
};

export default CustomerTypes;
