import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button
} from '@mui/material';
import PropTypes from 'prop-types';

function MessagePrompt({
  questionTitle,
  questionMessage,
  questionOpen,
  handleQuestionClose
}) {
  return (
    <Dialog
      open={questionOpen}
      onClose={() => handleQuestionClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{questionTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {questionMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleQuestionClose(true)}
          color="secondary"
          variant="contained"
        >
          Yes
        </Button>
        <Button
          onClick={() => handleQuestionClose(false)}
          color="primary"
          autoFocus
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}

MessagePrompt.propTypes = {
  questionTitle: PropTypes.string,
  questionMessage: PropTypes.string,
  questionOpen: PropTypes.bool,
  handleQuestionClose: PropTypes.func
};

export default MessagePrompt;
