import { React, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  IconButton,
  SvgIcon,
  Checkbox,
} from '@mui/material';
import { Trash as TrashIcon } from 'react-feather';
import { makeStyles } from 'tss-react/mui';
import MessagePrompt from 'src/components/MessagePrompt';

const useStyles = makeStyles()((theme) => ({
  root: {},
  confirmButton: {
    marginRight: theme.spacing(2),
  },
}));

function AddEditSupplierProductDensitiesForm({
  row,
  mode,
  onSave,
  onCancel,
  onDelete,
}) {
  const { classes } = useStyles();
  const [questionOpen, setQuestionOpen] = useState(false);
  const [questionTitle, setQuestionTitle] = useState('');
  const [questionMessage, setQuestionMessage] = useState('');
  const [updateCompartmentDensities, setupdateCompartmentDensities] =
    useState(false);

  const handleQuestionClose = (answer) => {
    setQuestionOpen(false);

    if (questionTitle === 'Update Compartments' && answer) {
      // row.updateCompartmentDensities=true;
      // setFieldValue('updateCompartmentDensities', true);
      console.log('What now');
      setupdateCompartmentDensities(true);
    }
  };

  return (
    <Formik
      initialValues={{
        product: row.product,
        l15Density: row.l15Density || 0,
        temperature: row.temperature || 0,
        density: row.density || 0,
        coe: row.coe || 0,
        updateCompartmentDensities: row.updateCompartmentDensities || false,
      }}
      validationSchema={Yup.object().shape({
        product: Yup.string().required('product is required'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const data = {
            ...values,
          };

          data.updateCompartmentDensities = updateCompartmentDensities;
          // data.forEach(element => {
          //   element.updateCompartmentDensities = updateCompartmentDensities;
          // });

          setStatus({ success: true });
          setSubmitting(false);

          onSave(data);
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <MessagePrompt
            questionTitle={questionTitle}
            questionMessage={questionMessage}
            questionOpen={questionOpen}
            handleQuestionClose={handleQuestionClose}
          />
          <Box p={2}>
            <Typography
              align="center"
              gutterBottom
              variant="h3"
              color="textPrimary"
            >
              {mode === 'add' ? 'Add Density' : 'Edit Density'}
            </Typography>
          </Box>

          <Box mt={2} style={{ margin: 10 }}>
            <TextField
              error={Boolean(touched.id && errors.id)}
              fullWidth
              autoFocus={mode === 'add'}
              helperText={touched.id && errors.id}
              label="Product"
              name="id"
              disabled="True"
              value={values.product}
              variant="outlined"
            />
          </Box>

          <Box mt={2} style={{ margin: 10 }}>
            <TextField
              error={Boolean(touched.l15Density && errors.l15Density)}
              fullWidth
              helperText={touched.l15Density && errors.l15Density}
              label="L15 Density"
              name="l15Density"
              onBlur={(e) => {
                var ambDensity = 0;
                if (values.coe != 0)
                  ambDensity =
                    Math.round(
                      10000 *
                        (values.l15Density /
                          (1 + values.coe * (values.temperature - 15)))
                    ) / 10000;

                var origAmbDensity = values.density; // need to store this so we call everytime we change
                setFieldValue('density', ambDensity);

                if (
                  origAmbDensity != ambDensity &&
                  ambDensity !== 0 &&
                  !values.updateCompartmentDensities
                ) {
                  setQuestionMessage(
                    'Update compartment densities for ' + values.product + ' ?'
                  );
                  setQuestionTitle('Update Compartments');
                  setQuestionOpen(true);
                }
              }}
              onChange={handleChange}
              value={values.l15Density}
              type="Number"
              variant="outlined"
            />
          </Box>

          <Box mt={2} style={{ margin: 10 }}>
            <TextField
              error={Boolean(touched.temperature && errors.temperature)}
              fullWidth
              helperText={touched.temperatue && errors.temperature}
              label="Temperature"
              name="temperature"
              onBlur={(e) => {
                var ambDensity = 0;
                if (values.coe != 0)
                  ambDensity =
                    Math.round(
                      10000 *
                        (values.l15Density /
                          (1 + values.coe * (values.temperature - 15)))
                    ) / 10000;

                setFieldValue('density', ambDensity);
              }}
              onChange={handleChange}
              value={values.temperature}
              type="Number"
              variant="outlined"
            />
          </Box>

          <Box mt={2} style={{ margin: 10 }}>
            <TextField
              error={Boolean(touched.density && errors.density)}
              fullWidth
              helperText={touched.density && errors.density}
              label="AMB Density"
              name="density"
              onBlur={(e) => {
                var l15Density = 0;
                if (values.coe != 0)
                  l15Density =
                    Math.round(
                      10000 *
                        (values.density *
                          (1 + values.coe * (values.temperature - 15)))
                    ) / 10000;

                // var origAmbDensity = values.density; // need to store this so we call everytime we change
                setFieldValue('l15Density', l15Density);

                // if (
                //   origAmbDensity != ambDensity &&
                //   ambDensity !== 0 &&
                //   !values.updateCompartmentDensities
                // ) {
                //   setQuestionMessage(
                //     'Update compartment densities for ' + values.product + ' ?'
                //   );
                //   setQuestionTitle('Update Compartments');
                //   setQuestionOpen(true);
                // }
              }}
              // onBlur={handleBlur}

              onChange={handleChange}
              value={values.density}
              type="Number"
              variant="outlined"
            />
          </Box>

          {/* <Box mt={2} style={{ margin: 10 }}>
            <TextField
              error={Boolean(touched.updateCompDensities && errors.updateCompDensities)}
              fullWidth              
              helperText={touched.updateCompDensities && errors.updateCompDensities}
              label="Update Comp Densities"
              name="updateCompDensities"
              isVisible="False"
              value={values.updateCompDensities}
              variant="outlined"
            />
          </Box> */}

          {/* <Box mt={2} style={{ margin: 10 }}>
            <TextField
              error={Boolean(touched.density && errors.density)}
              fullWidth
              helperText={touched.density && errors.density}
              label="COE"
              name="COE"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.coe}
              type="Number"
              variant="outlined"
            />
          </Box> */}

          <Divider />
          <Box p={2} display="flex" alignItems="center">
            {mode === 'deleteEnabled' && (
              <IconButton onClick={() => onDelete(values.id)}>
                <SvgIcon>
                  <TrashIcon />
                </SvgIcon>
              </IconButton>
            )}

            <Box flexGrow={1} />

            <Button
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              color="secondary"
              className={classes.confirmButton}
            >
              Confirm
            </Button>

            <Button onClick={onCancel}>Cancel</Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

AddEditSupplierProductDensitiesForm.propTypes = {
  row: PropTypes.object,
  mode: PropTypes.oneOf(['add', 'edit']).isRequired,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
};

AddEditSupplierProductDensitiesForm.defaultProps = {
  row: {},
  onSave: () => {},
  onCancel: () => {},
  onDelete: () => {},
};

export default AddEditSupplierProductDensitiesForm;
