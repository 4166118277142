import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Box,
  Container,
  Card,
  CardContent,
  Divider,
  Link,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Page from 'src/components/Page';
import OCLogo from 'src/components/OCLogo';
import useLocalStorage from 'src/utils/useLocalStorage';
import authService from 'src/services/authService';
import LoginForm from './LoginForm';

const useStyles = makeStyles()((theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    height: '100%',
    minHeight: '100vh',
    flexDirection: 'column',
  },
  backButton: {
    marginLeft: theme.spacing(2),
  },
  card: {
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%',
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  icon: {
    backgroundColor: 'transparent',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 79,
    width: 94,
  },
  cell: {
    borderBottom: 'none',
  },
  logo: {
    marginTop: -50,
    filter:
      theme.palette.type === 'light' ? 'brightness(0%)' : 'brightness(100%)',
  },
}));

function LoginView() {
  const { classes } = useStyles();
  const [user] = useLocalStorage('loggedInUser');

  const handleSubmitSuccess = () => {
    // window.location.reload();
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    authService.getAccessToken().then((token) => {
      if (authService.isValidToken(token)) {
        // window.location.reload();
      }
    });
  }, [user]);

  return (
    <Page className={classes.root} title="Login">
      <Container maxWidth="md">
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <Avatar className={classes.icon}>
              <OCLogo />
            </Avatar>
            <Typography variant="h2" color="textPrimary">
              Sign in
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Sign in to Loads Portal
            </Typography>
            <Box mt={3}>
              <LoginForm onSubmitSuccess={handleSubmitSuccess} />
            </Box>
            <Box my={2}>
              <Divider />
            </Box>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell className={classes.cell} align="left">
                    <Link
                      component={RouterLink}
                      to="/resetPassword"
                      variant="body2"
                      color="textSecondary"
                    >
                      Forgot password
                    </Link>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}

export default LoginView;
