import React, { useState } from 'react';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Collapse,
  ListItem,
  Typography,
  useTheme
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  ChevronDown as ExpandMoreIcon,
  ChevronUp as ExpandLessIcon
} from 'react-feather';

const useStyles = makeStyles()((theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium
      }
    }
  },
  active: {
    color: theme.palette.secondary.main,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium
      }
    }
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  }
}));

function NavItem({
  title,
  href,
  depth,
  children,
  icon: Icon,
  className,
  open: openProp,
  info: Info,
  exact,
  ...rest
}) {
  const { classes } = useStyles();
  const [open, setOpen] = useState(openProp);
  const history = useNavigate();
  const theme = useTheme();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleNavClick = () => {
    setTimeout(() => {
      const query = new URLSearchParams(window.location.search);
      query.append('return', href);
      return history(`/login?${query.toString()}`);
    }, 10);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 20 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button className={classes.button} onClick={handleToggle} style={style}>
          {Icon && <Icon className={classes.icon} size="20" />}
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
      {...rest}
    >
      <Button
        style={(active) =>
          active.isActive
            ? {
                color: theme.palette.secondary.main,
                paddingLeft,
                justifyContent: 'flex-start',
                textTransform: 'none',
                letterSpacing: 0,
                width: '100%',
                fontWeight: theme.typography.fontWeightRegular,
                '&.depth0': {
                  '& $title': {
                    fontWeight: theme.typography.fontWeightMedium
                  }
                }
              }
            : {
                paddingLeft
              }
        }
        className={classes.buttonLeaf}
        component={RouterLink}
        exact={exact.toString()}
        onClick={handleNavClick}
        to={href}
      >
        {Icon && <Icon className={classes.icon} size="20" />}
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        {Info && <Info className={classes.info} />}
      </Button>
    </ListItem>
  );
}

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  info: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  exact: PropTypes.bool
};

NavItem.defaultProps = {
  open: false
};

export default NavItem;
