import { useState, useLayoutEffect } from 'react';
import { useTheme } from '@mui/material';

export default function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  const theme = useTheme();

  useLayoutEffect(() => {
    function updateSize() {
      let width = window.innerWidth;
      let height = window.innerHeight;

      if (window.innerWidth < theme.breakpoints.values.lg) {
        width += 250;
      }

      if (window.innerWidth < theme.breakpoints.values.md) {
        width += 270;
      }

      width -= 600;
      height -= 65;

      setSize([width, height]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [theme]);
  return size;
}
