/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useLocation, matchPath } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  IconButton,
  Grid,
  Button,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  Clipboard as LoadManagerIcon,
  ChevronsLeft as CloseNavBarIcon,
  ChevronsRight as OpenNavBarIcon,
  Users as UserIcon,
} from 'react-feather';
import { useSelector } from 'react-redux';
import NavItem from './NavItem';

const navConfig = () => [
  {
    subheader: 'Office',
    key: 'Office',
    items: [
      {
        title: 'Load Manager',
        icon: LoadManagerIcon,
        href: '/app/loadManager',
      },
      {
        title: 'Users',
        icon: UserIcon,
        href: '/app/management/users',
      },
    ],
  },
];

const reduceChildRoutes = ({
  acc,
  pathname,
  item,
  depth = 0,
  navOpen,
  isFMSCustomer,
}) => {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(`${item.href}/*`, pathname);
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={navOpen ? item.title : ''}
        exact={Boolean(item.exact ?? false)}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items.filter((x) => !isFMSCustomer || !x.hideIfFMS),
          navOpen,
          isFMSCustomer,
        })}
      </NavItem>
    );
  } else {
    const open = matchPath(item.href, pathname);
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        open={Boolean(open)}
        key={key}
        info={item.info}
        title={navOpen ? item.title : ''}
        exact={Boolean(item.exact ?? false)}
      />
    );
  }

  return acc;
};

const renderNavItems = ({ items, navOpen, isFMSCustomer, ...rest }) => (
  <List disablePadding>
    {items.reduce(
      (acc, item) =>
        reduceChildRoutes({
          acc,
          item,
          navOpen,
          isFMSCustomer,
          ...rest,
        }),
      []
    )}
  </List>
);

const drawerWidth = 256;

const useStyles = makeStyles()((theme) => ({
  mobileDrawer: {
    width: 256,
    marginTop: 30,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));

function NavBar({ openMobile, onMobileClose }) {
  const { classes } = useStyles();
  const location = useLocation();
  const [navOpen, setNavOpen] = useState(
    localStorage.getItem('navOpen') !== 'false'
  );
  const { isFMSCustomer, isAdmin } = useSelector((state) => state.account);
  const [homeScreenButtonVisible, setHomeScreenButtonVisible] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    const checkNavOpen = () => {
      const item = localStorage.getItem('navOpen');

      if (item) {
        setNavOpen(item === 'true');
      }
    };

    window.addEventListener('storage', checkNavOpen);

    return () => {
      window.removeEventListener('storage', checkNavOpen);
    };
  }, []);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const handleHomeScreenButtonClick = () => {
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choice) => {
      setHomeScreenButtonVisible(false);
      setDeferredPrompt(null);
      if ('Notification' in window && choice.outcome === 'accepted') {
        Notification.requestPermission();
      }
    });
  };

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setHomeScreenButtonVisible(true);
    });
  });

  const content = (
    <Box
      style={{ marginTop: openMobile ? 0 : 50 }}
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Hidden lgUp>
        <Box p={1} display="flex" justifyContent="center" />
      </Hidden>

      <Box p={2}>
        {navConfig(navOpen || openMobile)
          .filter(
            (x) => (isAdmin || !x.adminOnly) && (!isFMSCustomer || !x.hideIfFMS)
          )
          .map((config) => (
            <List
              key={config.key}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: config.items.filter(
                  (x) => !isFMSCustomer || !x.hideIfFMS
                ),
                pathname: location.pathname,
                navOpen: navOpen || openMobile,
                isFMSCustomer,
              })}
            </List>
          ))}
      </Box>

      <Box flex={1} flexGrow={1} />
      {openMobile ? null : (
        <>
          {navOpen ? (
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <IconButton
                size="small"
                onClick={() => {
                  localStorage.setItem('navOpen', !navOpen);
                  setNavOpen(!navOpen);
                  window.dispatchEvent(new Event('storage'));
                }}
              >
                <CloseNavBarIcon />
              </IconButton>
            </Grid>
          ) : (
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <IconButton
                size="small"
                onClick={() => {
                  localStorage.setItem('navOpen', !navOpen);
                  setNavOpen(!navOpen);
                  window.dispatchEvent(new Event('storage'));
                }}
              >
                <OpenNavBarIcon />
              </IconButton>
            </Grid>
          )}
        </>
      )}

      {homeScreenButtonVisible ? (
        <Button
          variant="contained"
          color="secondary"
          onClick={handleHomeScreenButtonClick}
        >
          Add to home screen
        </Button>
      ) : null}
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: navOpen,
            [classes.drawerClose]: !navOpen,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: navOpen,
              [classes.drawerClose]: !navOpen,
            }),
          }}
          variant="permanent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
