import React, { useState, useRef, useEffect, setBackdropOpen } from 'react';
import {
  Box,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Card,
  CardContent,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Page from 'src/components/Page';
import PrintToolbar from 'src/components/PrintToolbar';
import useWindowSize from 'src/hooks/useWindowSize';
import axios from 'src/utils/axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useSnackbar } from 'notistack';
import SimpleSearchControl from 'src/components/SimpleSearchControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TruckUtilisation from './TruckUtilisation';
import LoadGrid from './LoadGrid';
import SupplierDensities from './SupplierDensities';
import AddEditNewLoadPlanForm2 from './NewLoadPlanModal';

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
}));

const loadProgramOptions = [
  { id: 0, description: 'General Mass Load' },
  { id: 1, description: 'Concessional Mass Load' },
  { id: 2, description: 'High Mass Load' },
  { id: 3, description: 'Performance Based Standards' },
];

const loadOptions = [
  { id: 0, description: 'Load Maximum' },
  { id: 2, description: 'Fill To Required' },
  { id: 3, description: 'Load To Orders' },
  { id: 4, description: 'Load To Orders V2' },
];

function LoadManagerView() {
  const { classes } = useStyles();
  const [addEditOpen, setAddEditOpen] = useState(false);

  const organisationRef = useRef();
  const [loadPlan, setLoadPlan] = useState('');
  const [selectedLoadPlan, setSelectedLoadPlan] = useState(null);

  const [loadPlanDate, setLoadPlanDate] = useState(null);
  const [supplyPoint, setSupplyPoint] = useState('');
  const [selectedSupplyPoint, setSelectedSupplyPoint] = useState(null);
  const [driver, setDriver] = useState('');

  const [selectedTruck, setTruck] = useState('');
  const [selectedNotes, setNotes] = useState('');

  const [data, setData] = useState(null);
  const [supplierDensityRows, setSupplierDensityRows] = useState([]);
  const [refreshCompartmentGrid, setrefreshCompartmentGrid] = useState(false);

  const [, windowHeight] = useWindowSize();
  const [selectedLoadOption, setSelectedLoadOption] = useState(0);
  const [selectedLoadProgram, setSelectedLoadProgram] = useState(0);
  const [compData, setCompData] = useState([]);
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  // const getData = useCallback(() => {
  //   axios
  //     .get('/api/customerSite', {
  //       params: {
  //         customer,
  //       },
  //     })
  //     .then((response) => {
  //       if (isMountedRef.current) {
  //         setData(response.data);
  //       }
  //     });
  // }, [isMountedRef, customer]);

  // useEffect(() => {
  //   getData();
  // }, [getData]);

  // Load saved  data for loadplan
  const ReLoadLoadPlan = () => {
    if (loadPlan !== '') {
      const endPoint = `/api/LoadPlanController/loadPlans/${loadPlan}`;

      axios
        .get(endPoint)
        .then((response) => {
          if (isMountedRef.current) {
            setData(response.data);
            setLoadPlanDate(response.data?.date);
            setSelectedLoadProgram(response.data?.loadProgram);
            setSupplyPoint(response.data?.supplyPoint);
            setDriver(response.data?.driver);
            setTruck(response.data?.truck);
            setNotes(response.data?.notes);
            setSelectedLoadProgram(response.data?.loadProgram);
          }
        })
        .catch((error) => {
          enqueueSnackbar(error?.response?.data?.message ?? error.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        });
    }
  };

  const closeAddEditDialog = () => {
    setAddEditOpen(false);
    // setEditingId('');
  };

  const NewSave = (newData) => {
    axios
      .post(
        `api/LoadPlanController/CreateLoadPlan/New`, // loadPlan ${newData.LoadPlan}
        JSON.stringify(newData)
      )

      .then((response) => {
        if (isMountedRef.current) {
          setLoadPlan(response?.data ?? loadPlan); // if dud return leave loadplan set as it was
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        // setBackdropOpen(false);
      });
  };

  const save = (newData) => {
    const newlpTruck = newData.config; // need to look up config if built on the fly
    const newlpSupplier = 'CALTEX';

    // newData.loadPlan = 'NEW';
    NewSave(newData);

    // NewSave({
    //   LoadPlan: 'NEW',
    //   Truck: newlpTruck,
    //   Supplier: newlpSupplier,
    // });
    // updateCustomerTypeMutation.mutate({ data: newData });
  };

  // KindaHowCustomerType thing works below
  // const save = (newData) => {
  //   updateCustomerTypeMutation.mutate({ data: newData });
  // };

  const handleAddEditSave = (event) => {
    setAddEditOpen(false);
    // setEditingId('');
    // need to build in logic here to take the returned? load plan number save it with details and reload it??
    save(event);
  };

  // const compartmentUpdated = () => {
  //   // setBackdropOpen(true);

  //   ReLoadLoadPlan();
  // };

  const SaveNewLoadPlan = (newData) => {
    // updateCustomerTypeMutation.mutate({ data: newData });
  };

  const Save = (newData) => {
    axios
      .post(
        `api/LoadPlanController/SaveLoadPlan/${loadPlan}`,
        JSON.stringify(newData)
      )

      .then((response) => {
        if (isMountedRef.current) {
          setLoadPlan(response?.data ?? loadPlan); // if dud return leave loadplan set as it was
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        // setBackdropOpen(false);
      });
  };

  useEffect(() => {
    ReLoadLoadPlan();
  }, [loadPlan]);

  return (
    <Page
      className={classes.root}
      style={{ height: windowHeight }}
      title="Load Manager"
    >
      <Box p={2} display="flex" alignItems="center">
        <Box flexGrow={1} />
        <PrintToolbar
          reportLocation="loadPlan"
          reportName="loadPlanReport"
          data={{ LoadPlan: loadPlan }}
        />
      </Box>
      <Box p={2}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="start"
          style={{ marginTop: 5 }}
          spacing={1}
        >
          <Grid item md={9} xs={12}>
            <Card>
              <CardContent>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{ marginTop: 10 }}
                >
                  <Grid item xl={7.5} md={6.5} xs={6.6}>
                    <FormControl fullWidth>
                      <SimpleSearchControl
                        label="Load Plan Number"
                        searchFormType="LoadPlan"
                        defaultValue={loadPlan}
                        setSelectedValue={setLoadPlan}
                        descriptionColumn="description"
                        showDescriptionInLabel
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xl={2} md={2} xs={2} style={{ marginLeft: 10 }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      style={{ padding: 15 }}
                      onClick={() =>
                        Save({
                          LoadPlan: loadPlan,
                          Truck: selectedTruck,
                          Supplier: supplyPoint,
                          Driver: driver,
                          LoadPlanDate: loadPlanDate,
                          LoadProgram: selectedLoadProgram,
                          Notes: selectedNotes,
                        })
                      }
                    >
                      Save
                    </Button>
                  </Grid>
                  <Grid item xl={2} md={2} xs={2} style={{ marginLeft: 10 }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      style={{ padding: 15 }}
                      onClick={() =>
                        // NewSave({
                        //   LoadPlan: 'NEW',
                        //   Truck: selectedTruck,
                        //   Supplier: '',
                        // })
                        setAddEditOpen(true)
                      }
                    >
                      New
                    </Button>
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{ marginTop: 10 }}
                >
                  <Grid xl={7.5} md={6.5} xs={6.6}>
                    <DatePicker
                      fullWidth
                      inputVariant="outlined"
                      label="Load Plan Date"
                      inputFormat="DD/MM/yyyy"
                      value={loadPlanDate}
                      onChange={(date) => {
                        setLoadPlanDate(date);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      renderInput={(props) => (
                        <TextField fullWidth {...props} />
                      )}
                    />
                  </Grid>
                </Grid>

                <TextField
                  label={data?.truck ?? 'Truck'}
                  fullWidth
                  value={selectedTruck}
                  onChange={(e) => setTruck(e.target.value)}
                  style={{ marginTop: 10 }}
                />
                <SimpleSearchControl
                  label="Driver"
                  searchFormType="Drivers"
                  defaultValue={driver}
                  setSelectedValue={setDriver}
                  descriptionColumn="name"
                  showDescriptionInLabel
                  style={{ marginTop: 10 }}
                />
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{ marginTop: 5 }}
                  spacing={1}
                >
                  <Grid item md={6} xs={12}>
                    <SimpleSearchControl
                      label="Supply Point"
                      searchFormType="Suppliers"
                      defaultValue={supplyPoint}
                      setSelectedValue={setSupplyPoint}
                      descriptionColumn="description"
                      showDescriptionInLabel
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Load Program</InputLabel>
                      <Select
                        value={selectedLoadProgram}
                        label="Load Program"
                        onChange={(e) => setSelectedLoadProgram(e.target.value)}
                      >
                        {loadProgramOptions.map((x) => (
                          <MenuItem key={x.id} value={x.id}>
                            {x.description}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <TextField
                      label={data?.notes ?? 'Notes'}
                      fullWidth
                      onChange={(e) => setNotes(e.target.value)}
                      multiline="True"
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{ marginTop: 10 }}
                >
                  <Grid item xl={9.7} md={8.5} xs={8.6}>
                    <FormControl fullWidth>
                      <InputLabel>Load Option</InputLabel>
                      <Select
                        value={selectedLoadOption}
                        label="Load Option"
                        onChange={(e) => setSelectedLoadOption(e.target.value)}
                      >
                        {loadOptions.map((x) => (
                          <MenuItem key={x.id} value={x.id}>
                            {x.description}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xl={2} md={3} xs={3} style={{ marginLeft: 10 }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      style={{ padding: 15 }}
                    >
                      Generate
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item md={3} xs={12}>
            <Card>
              <CardContent>
                <TruckUtilisation loadPlan={loadPlan} compData={compData} />
              </CardContent>
            </Card>
            <Card>
              <SupplierDensities
                loadPlan={loadPlan}
                compData={compData}
                supplier={supplyPoint}
                loadPlanDate={loadPlanDate}
                supplierDensityRows={supplierDensityRows}
                setSupplierDensityRows={setSupplierDensityRows}
                refreshCompartmentGrid={refreshCompartmentGrid}
                setrefreshCompartmentGrid={setrefreshCompartmentGrid}
              />
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box p={2} style={{ marginTop: -15 }}>
        <LoadGrid
          compData={compData}
          setCompData={setCompData}
          loadPlan={loadPlan}
          supplier={supplyPoint}
          loadPlanDate={loadPlanDate}
          supplierDensityRows={supplierDensityRows}
          refreshCompartmentGrid={refreshCompartmentGrid}
        />
      </Box>
      <AddEditNewLoadPlanForm2
        // row={data.filter((x) => x.id === editingId)[0]}
        mode="add"
        onSave={handleAddEditSave}
        onCancel={closeAddEditDialog}
        // onDelete={handleAddEditDelete}
        open={addEditOpen}
      />
    </Page>
  );
}

export default LoadManagerView;
