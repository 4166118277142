import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  IconButton,
  SvgIcon,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { Trash as TrashIcon } from 'react-feather';
import { makeStyles } from 'tss-react/mui';
import SimpleSearchControl from 'src/components/SimpleSearchControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const useStyles = makeStyles()((theme) => ({
  root: {},
  confirmButton: {
    marginRight: theme.spacing(2),
  },
}));

function AddEditNewLoadPlanForm2({ row, mode, onSave, onCancel, onDelete }) {
  const { classes } = useStyles();

  const [loadPlanDate, setLoadPlanDate] = useState(null);

  const [supplyPoint, setSupplyPoint] = useState('');
  const [selectedSupplyPoint, setSelectedSupplyPoint] = useState(null);

  const [driver, setDriver] = useState('');
  const [selectedDriver, setSelectedDriver] = useState(null);

  const [truckCode, setTruckCode] = useState('');
  const [selectedTruckCode, setSelectedTruckCode] = useState(null);

  const [trailer1Search, setTrailer1Search] = useState('');
  const [selectedTrailer1Search, setSelectedTrailer1Search] = useState(null);

  const [trailer2Search, setTrailer2Search] = useState('');
  const [selectedTrailer2Search, setSelectedTrailer2Search] = useState(null);

  const [trailer3Search, setTrailer3Search] = useState('');
  const [selectedTrailer3Search, setSelectedTrailer3Search] = useState(null);

  const [trailer4Search, setTrailer4Search] = useState('');
  const [selectedTrailer4Search, setSelectedTrailer4Search] = useState(null);

  const [dolly1Search, setDolly1Search] = useState('');
  const [selectedDolly1Search, setSelectedDolly1Search] = useState(null);

  const [dolly2Search, setDolly2Search] = useState('');
  const [selectedDolly2Search, setSelectedDolly2Search] = useState(null);

  const [dolly3Search, setDolly3Search] = useState('');
  const [selectedDolly3Search, setSelectedDolly3Search] = useState(null);

  return (
    <Formik
      initialValues={{
        loadPlan: row.loadPlan || '',
        loadPlanDate: row.loadPlanDate || '',
        supplyPoint: row.supplyPoint || '',
        driver: row.driver || '',
        buildConfigOnFly: row.buildConfig || true,
        config: row.config || '',
        truckCode: row.truckCode || '',
        trailer1: row.trailer1 || '',
        trailer2: row.trailer2 || '',
        trailer3: row.trailer3 || '',
        trailer4: row.trailer4 || '',
        dolly1: row.dolly1 || '',
        dolly2: row.dolly2 || '',
        dolly3: row.dolly3 || '',
      }}
      // validationSchema={Yup.object().shape({
      //   config: Yup.string().required('Config is required'),
      //   // description: Yup.string().required('Description is required'),
      // })}
      onSubmit={async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
      ) => {
        try {
          const data = {
            ...values,
          };

          resetForm();
          setStatus({ success: true });
          setSubmitting(false);

          onSave(data);
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box p={2}>
            <Typography
              align="center"
              gutterBottom
              variant="h3"
              color="textPrimary"
            >
              {mode === 'add' ? 'Add Load Plan' : 'Edit Load Plan'}
            </Typography>
          </Box>

          {/* <Box mt={2} style={{ margin: 10 }}>
            <TextField
              error={Boolean(touched.loadPlan && errors.loadPlan)}
              fullWidth
              helperText={touched.loadPlan && errors.loadPlan}
              label="Load Plan"
              name="loadPlan"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.loadPlan}
              variant="outlined"
              hidden
            />
          </Box>

          <Box mt={2} style={{ margin: 10 }}>
            <TextField
              error={Boolean(touched.config && errors.config)}
              fullWidth
              helperText={touched.config && errors.config}
              label="Configuration"
              name="config"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.config}
              variant="outlined"
            />
          </Box> */}

          <Box mt={2} style={{ margin: 10 }}>
            <DatePicker
              fullWidth
              inputVariant="outlined"
              label="Load Plan Date"
              inputFormat="DD/MM/yyyy"
              value={values.loadPlanDate}
              onChange={(date) => {
                setFieldValue('loadPlanDate', date);
                // setLoadPlanDate(date);
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              renderInput={(props) => <TextField fullWidth {...props} />}
            />
          </Box>
          <Box mt={2} style={{ margin: 10 }}>
            <SimpleSearchControl
              label="Supply Point"
              labelOverride="Supply Point"
              searchFormType="Suppliers"
              defaultValue={values.supplyPoint}
              setSelectedValue={(e) => setFieldValue('supplyPoint', e)} // {setSupplyPoint}
              descriptionColumn="description"
              showDescriptionInLabel
            />
          </Box>

          <Box mt={2} style={{ margin: 10 }}>
            <SimpleSearchControl
              label="Driver"
              labelOverride="Driver"
              searchFormType="Drivers"
              defaultValue={values.driver}
              setSelectedValue={(e) => setFieldValue('driver', e)}
              descriptionColumn="name"
              showDescriptionInLabel
            />
          </Box>

          <Box mt={2} style={{ margin: 10 }}>
            <SimpleSearchControl
              labelOverride="Truck"
              searchFormType="Trucks"
              defaultValue={values.truckCode}
              value={values.truckCode}
              setSelectedValue={(e) => setFieldValue('truckCode', e)}
              descriptionColumn="description"
              showDescriptionInLabel
            />
          </Box>

          <Box mt={2} style={{ margin: 10 }}>
            <SimpleSearchControl
              labelOverride="Trailer 1"
              searchFormType="Trailers"
              value={values.trailer1}
              defaultValue={values.trailer1}
              setSelectedValue={(e) => setFieldValue('trailer1', e)}
              descriptionColumn="description"
              showDescriptionInLabel
            />
          </Box>

          <Box mt={2} style={{ margin: 10 }}>
            <SimpleSearchControl
              labelOverride="Dolly 1"
              searchFormType="Dollys"
              defaultValue={values.dolly1}
              setSelectedValue={(e) => setFieldValue('dolly1', e)}
              descriptionColumn="description"
              showDescriptionInLabel
            />
          </Box>

          <Box mt={2} style={{ margin: 10 }}>
            <SimpleSearchControl
              labelOverride="Trailer 2"
              searchFormType="Trailers"
              defaultValue={values.trailer2}
              setSelectedValue={(e) => setFieldValue('trailer2', e)}
              descriptionColumn="description"
              showDescriptionInLabel
            />
          </Box>

          <Box mt={2} style={{ margin: 10 }}>
            <SimpleSearchControl
              labelOverride="Dolly 2"
              searchFormType="Dollys"
              defaultValue={values.dolly2}
              setSelectedValue={(e) => setFieldValue('dolly2', e)}
              descriptionColumn="description"
              showDescriptionInLabel
            />
          </Box>

          <Box mt={2} style={{ margin: 10 }}>
            <SimpleSearchControl
              labelOverride="Trailer 3"
              searchFormType="Trailers"
              defaultValue={values.trailer3}
              setSelectedValue={(e) => setFieldValue('trailer3', e)}
              descriptionColumn="description"
              showDescriptionInLabel
            />
          </Box>

          <Box mt={2} style={{ margin: 10 }}>
            <SimpleSearchControl
              labelOverride="Dolly 3"
              searchFormType="Dollys"
              defaultValue={values.dolly3}
              setSelectedValue={(e) => setFieldValue('dolly3', e)}
              descriptionColumn="description"
              showDescriptionInLabel
            />
          </Box>

          <Box mt={2} style={{ margin: 10 }}>
            <SimpleSearchControl
              labelOverride="Trailer 4"
              searchFormType="Trailers"
              defaultValue={values.trailer4}
              setSelectedValue={(e) => setFieldValue('trailer4', e)}
              descriptionColumn="description"
              showDescriptionInLabel
            />
          </Box>

          {/* <FormGroup row style={{ margin: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.cod}
                  name="cod"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                />
              }
              label="COD"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={values.balanceForward}
                  name="balanceForward"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                />
              }
              label="Balance Forward"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={values.voucherCustomer}
                  name="voucherCustomer"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                />
              }
              label="Voucher Customer"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={values.dfoa}
                  name="dfoa"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                />
              }
              label="DFOA"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={values.captiveAccount}
                  name="captiveAccount"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                />
              }
              label="Captive Account"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={values.ampolBot}
                  name="ampolBot"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                />
              }
              label="Ampol Bot"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={values.prePayment}
                  name="prePayment"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                />
              }
              label="Pre Payment"
            />
          </FormGroup> */}

          <Divider />
          <Box p={2} display="flex" alignItems="center">
            {mode === 'edit' && (
              <IconButton onClick={() => onDelete(values.id)}>
                <SvgIcon>
                  <TrashIcon />
                </SvgIcon>
              </IconButton>
            )}

            <Box flexGrow={1} />

            <Button
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              color="secondary"
              className={classes.confirmButton}
            >
              Confirm
            </Button>

            <Button onClick={onCancel}>Cancel</Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

AddEditNewLoadPlanForm2.propTypes = {
  row: PropTypes.object,
  mode: PropTypes.oneOf(['add', 'edit']).isRequired,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  // onDelete: PropTypes.func,
  // open: PropTypes.bool,
};

AddEditNewLoadPlanForm2.defaultProps = {
  row: {},
  onSave: () => {},
  onCancel: () => {},
  // onDelete: () => {},
};

export default AddEditNewLoadPlanForm2;

// import React from 'react';
// import PropTypes from 'prop-types';
// import * as Yup from 'yup';
// import { Formik } from 'formik';
// import {
//   Box,
//   Typography,
//   TextField,
//   Button,
//   Divider,
//   IconButton,
//   SvgIcon,
//   FormGroup,
//   FormControlLabel,
//   Checkbox,
// } from '@mui/material';
// import { Trash as TrashIcon } from 'react-feather';
// import { makeStyles } from 'tss-react/mui';

// const useStyles = makeStyles()((theme) => ({
//   root: {},
//   confirmButton: {
//     marginRight: theme.spacing(2),
//   },
// }));

// function AddEditNewLoadPlanForm({ row, mode, onSave, onCancel, onDelete }) {
//   const { classes } = useStyles();

//   return (
//     <Formik
//       initialValues={{
//         loadPlan: row.loadPlan || '',
//         supplyPoint: row.supplyPoint || '',
//         config: row.config || false,
//         truck: row.truck || false,
//         trailer1: row.trailer1 || false,
//         trailer2: row.trailer2 || false,
//         trailer3: row.trailer3 || false,
//         trailer4: row.trailer4 || false,
//       }}
//       validationSchema={Yup.object().shape({
//         config: Yup.string().required('Config is required'),
//         // description: Yup.string().required('Description is required'),
//       })}
//       onSubmit={async (
//         values,
//         { resetForm, setErrors, setStatus, setSubmitting }
//       ) => {
//         try {
//           const data = {
//             ...values,
//           };

//           resetForm();
//           setStatus({ success: true });
//           setSubmitting(false);

//           onSave(data);
//         } catch (error) {
//           setStatus({ success: false });
//           setErrors({ submit: error.message });
//           setSubmitting(false);
//         }
//       }}
//     >
//       {({
//         errors,
//         handleBlur,
//         handleChange,
//         handleSubmit,
//         isSubmitting,
//         touched,
//         values,
//       }) => (
//         <form onSubmit={handleSubmit}>
//           <Box p={2}>
//             <Typography
//               align="center"
//               gutterBottom
//               variant="h3"
//               color="textPrimary"
//             >
//               {mode === 'add' ? 'Add Load Plan' : 'Edit Load Plan'}
//             </Typography>
//           </Box>

//           <Box mt={2} style={{ margin: 10 }}>
//             <TextField
//               error={Boolean(touched.loadPlan && errors.loadPlan)}
//               fullWidth
//               helperText={touched.loadPlan && errors.loadPlan}
//               label="Load Plan"
//               name="loadPlan"
//               onBlur={handleBlur}
//               onChange={handleChange}
//               value={values.loadPlan}
//               variant="outlined"
//             />
//           </Box>

//           <Box mt={2} style={{ margin: 10 }}>
//             <TextField
//               error={Boolean(touched.config && errors.config)}
//               fullWidth
//               helperText={touched.config && errors.config}
//               label="Configuration"
//               name="config"
//               onBlur={handleBlur}
//               onChange={handleChange}
//               value={values.config}
//               variant="outlined"
//             />
//           </Box>

//           {/* <FormGroup row style={{ margin: 10 }}>
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={values.cod}
//                   name="cod"
//                   onBlur={handleBlur}
//                   onChange={handleChange}
//                   variant="outlined"
//                 />
//               }
//               label="COD"
//             />

//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={values.balanceForward}
//                   name="balanceForward"
//                   onBlur={handleBlur}
//                   onChange={handleChange}
//                   variant="outlined"
//                 />
//               }
//               label="Balance Forward"
//             />

//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={values.voucherCustomer}
//                   name="voucherCustomer"
//                   onBlur={handleBlur}
//                   onChange={handleChange}
//                   variant="outlined"
//                 />
//               }
//               label="Voucher Customer"
//             />

//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={values.dfoa}
//                   name="dfoa"
//                   onBlur={handleBlur}
//                   onChange={handleChange}
//                   variant="outlined"
//                 />
//               }
//               label="DFOA"
//             />

//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={values.captiveAccount}
//                   name="captiveAccount"
//                   onBlur={handleBlur}
//                   onChange={handleChange}
//                   variant="outlined"
//                 />
//               }
//               label="Captive Account"
//             />

//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={values.ampolBot}
//                   name="ampolBot"
//                   onBlur={handleBlur}
//                   onChange={handleChange}
//                   variant="outlined"
//                 />
//               }
//               label="Ampol Bot"
//             />

//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={values.prePayment}
//                   name="prePayment"
//                   onBlur={handleBlur}
//                   onChange={handleChange}
//                   variant="outlined"
//                 />
//               }
//               label="Pre Payment"
//             />
//           </FormGroup> */}

//           <Divider />
//           <Box p={2} display="flex" alignItems="center">
//             {mode === 'edit' && (
//               <IconButton onClick={() => onDelete(values.id)}>
//                 <SvgIcon>
//                   <TrashIcon />
//                 </SvgIcon>
//               </IconButton>
//             )}

//             <Box flexGrow={1} />

//             <Button
//               variant="contained"
//               type="submit"
//               disabled={isSubmitting}
//               color="secondary"
//               className={classes.confirmButton}
//             >
//               Confirm
//             </Button>

//             <Button onClick={onCancel}>Cancel</Button>
//           </Box>
//         </form>
//       )}
//     </Formik>
//   );
// }

// AddEditNewLoadPlanForm.propTypes = {
//   row: PropTypes.object,
//   mode: PropTypes.oneOf(['add', 'edit']).isRequired,
//   onSave: PropTypes.func,
//   onCancel: PropTypes.func,
//   onDelete: PropTypes.func,
// };

// AddEditNewLoadPlanForm.defaultProps = {
//   row: {},
//   onSave: () => {},
//   onCancel: () => {},
//   onDelete: () => {},
// };

// export default AddEditNewLoadPlanForm;
