import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

function GuestGuard({ children }) {
  const account = useSelector((state) => state.account);
  const query = new URLSearchParams(window.location.search);

  if (account.user) {
    let returnPath = query.get('return');
    if (returnPath) {
      query.delete('return');
      const params = query.toString();
      if (params !== '') {
        returnPath = `${returnPath}?${params}`;
      }
      return <Navigate to={returnPath} replace />;
    }
    return <Navigate to="/app" replace />;
  }

  return children;
}

GuestGuard.propTypes = {
  children: PropTypes.any
};

export default GuestGuard;
