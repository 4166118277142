/* eslint-disable max-len */
import React, {
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
  useCallback,
  useEffect,
} from 'react';
import {
  Avatar,
  Box,
  Card,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Search as SearchIcon } from 'react-feather';
import { makeStyles } from 'tss-react/mui';
import getInitials from 'src/utils/getInitials';
import { CSVLink } from 'react-csv';
import axios from 'src/utils/axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useSnackbar } from 'notistack';

function applyFilters(users, query) {
  return users?.filter((user) => {
    let matches = true;

    if (query) {
      const properties = ['firstName', 'lastName', 'email'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (user[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    return matches;
  });
}

function applyPagination(users, page, limit) {
  return users?.slice(page * limit, page * limit + limit);
}

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  queryField: {
    width: 500,
  },
  bulkOperations: {
    position: 'relative',
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.tooltip + 1,
    color: '#fff',
  },
}));

const Results = forwardRef((props, ref) => {
  const { classes } = useStyles();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(50);
  const [query, setQuery] = useState('');
  const [csvData, setCSVData] = useState([]);
  const csvRef = useRef();
  const [users, setUsers] = useState(null);
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const getData = useCallback(() => {
    axios
      .get('/api/user')
      .then((response) => {
        if (isMountedRef.current) {
          setUsers(response.data);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error?.response?.data?.message ?? error.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      });
  }, [isMountedRef, enqueueSnackbar]);

  useEffect(() => {
    getData();
  }, [getData]);

  useImperativeHandle(ref, () => ({
    setup(usersProp) {
      setUsers(usersProp);
    },

    exportGrid() {
      const data = [];

      const header = ['Name', 'Email'];
      data.push(header);

      for (let index = 0; index < users.length; index++) {
        const rowData = users[index];
        const row = [`${rowData.firstName} ${rowData.lastName}`, rowData.email];
        data.push(row);
      }

      setCSVData(data);

      setTimeout(() => {
        if (csvRef.current?.link) {
          csvRef.current.link.click();
        }
      }, 300);
    },
  }));

  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  // Usually query is done on backend with indexing solutions
  const filteredUsers = applyFilters(users, query);
  const paginatedUsers = applyPagination(filteredUsers, page, limit);

  return (
    <>
      <Typography
        variant="h4"
        color="textPrimary"
        style={{ marginBottom: 10, fontStyle: 'italic' }}
      >
        Add Users In OC
      </Typography>

      <Card className={classes.root}>
        <CSVLink
          data={csvData}
          filename="Users.csv"
          className="hidden"
          ref={csvRef}
          target="_blank"
        />

        <Box p={2} minHeight={56} display="flex" alignItems="center">
          <TextField
            className={classes.queryField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            onChange={handleQueryChange}
            placeholder="Search users"
            value={query}
            variant="outlined"
          />
          <Box flexGrow={1} />
        </Box>
        <Box minWidth={700}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedUsers?.map((user) => (
                <TableRow hover key={user.id}>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Avatar className={classes.avatar} src={user.avatar}>
                        {getInitials(`${user.firstName} ${user.lastName}`)}
                      </Avatar>
                      <div>
                        <Typography variant="h6" align="center">
                          {user.firstName} {user.lastName}
                        </Typography>
                      </div>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Typography variant="h6" align="center">
                        {user.email}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <TablePagination
          component="div"
          count={filteredUsers?.length ?? 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[50, 100, 150]}
        />
      </Card>
    </>
  );
});

export default Results;
