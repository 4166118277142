import { combineReducers } from 'redux';
import accountReducer from './accountReducer';
import truckEventReducer from './truckEventReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  truckEvents: truckEventReducer
});

export default rootReducer;
