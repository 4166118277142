import React, { useState } from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  TextField,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import axios from 'src/utils/axios';
import { makeStyles } from 'tss-react/mui';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles()((theme) => ({
  root: {},
  backdrop: {
    zIndex: theme.zIndex.tooltip + 1,
    color: '#fff',
  },
}));

function RegisterForm({ className, onSubmitSuccess, ...rest }) {
  const { classes } = useStyles();
  const [backdropOpen, setBackdropOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <div>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Formik
        initialValues={{
          email: '',
          password: '',
          confirmPassword: '',
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('Must be a valid email')
            .max(255)
            .required('Email is required'),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            setBackdropOpen(true);
            axios
              .post('/api/verification/EmailPasswordReset', null, {
                params: {
                  emailAddress: values.email,
                },
              })
              .then(() => {
                setBackdropOpen(false);
                enqueueSnackbar('Email Sent', {
                  variant: 'success',
                });
                setTimeout(() => {
                  onSubmitSuccess();
                }, 500);
              })
              .catch((error) => {
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
                setBackdropOpen(false);
              });
          } catch (error) {
            setStatus({ success: false });
            setErrors({ submit: error.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
          >
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label="Email Address"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />
            <Box mt={2}>
              <Button
                color="secondary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Send
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </div>
  );
}

RegisterForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
};

RegisterForm.default = {
  onSubmitSuccess: () => {},
};

export default RegisterForm;
