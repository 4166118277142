import React from 'react';
import { Typography } from '@mui/material';

function Header({ ...rest }) {
  return (
    <div {...rest}>
      <Typography variant="h3" color="textPrimary">
        Settings
      </Typography>
    </div>
  );
}

export default Header;
