import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  IconButton,
  SvgIcon,
} from '@mui/material';
import { Trash as TrashIcon } from 'react-feather';
import { makeStyles } from 'tss-react/mui';
import SimpleSearchControl from 'src/components/SimpleSearchControl';
import { values } from 'lodash';

const useStyles = makeStyles()((theme) => ({
  root: {},
  confirmButton: {
    marginRight: theme.spacing(2),
  },
}));

const LoadProductDensity = () => {
  // if (product !== '' && density ==0) {
  //   const endPoint = `/api/LoadPlanController/loadPlans/${loadPlan}`;
  //   axios
  //     .get(endPoint)
  //     .then((response) => {
  // if (isMountedRef.current) {
  //   setData(response.data);
  //   setLoadPlanDate(response.data?.date);
  //   setSelectedLoadProgram(response.data?.loadProgram);
  //   // setSelectedSupplyPoint(response.data?.supplyPoint);
  //   setSupplyPoint(response.data?.supplyPoint);
  //   setNotes(response.data?.notes);
  //   setSelectedLoadProgram(response.data?.loadProgram);
  //   console.log(response.data);
  // }
  // })
  // .catch((error) => {
  //   enqueueSnackbar(error?.response?.data?.message ?? error.message, {
  //     variant: 'error',
  //     autoHideDuration: 3000,
  //   });
  // });
  // }
  values.density = '0.5';
};

function AddEditForm({
  row,
  mode,
  onSave,
  onCancel,
  onDelete,
  supplierDensityRows,
}) {
  const { classes } = useStyles();
  const [selectedProduct, setSelectedProduct] = useState('');
  const [originProduct, setOriginalProduct] = useState(row.product || '');

  useEffect(() => {
    LoadProductDensity();
  }, [selectedProduct]);

  return (
    <Formik
      initialValues={{
        id: row.id,
        product: row.product || '',
        sfl: row.sfl || 0,
        density: row.density || 0,
        volume: row.volume || 0,
      }}
      validationSchema={Yup.object().shape({
        // product: Yup.string().required('product is required'), //This line caused issues adding new product fix later
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const data = {
            ...values,
          };

          setStatus({ success: true });
          setSubmitting(false);

          onSave(data);
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box p={2}>
            <Typography
              align="center"
              gutterBottom
              variant="h3"
              color="textPrimary"
            >
              {mode === 'add' ? 'Add Compartment' : 'Edit Compartment'}
            </Typography>
          </Box>

          <Box mt={2} style={{ margin: 10 }}>
            <TextField
              error={Boolean(touched.id && errors.id)}
              fullWidth
              autoFocus={mode === 'add'}
              helperText={touched.id && errors.id}
              label="Comp"
              name="id"
              disabled
              value={values.id}
              variant="outlined"
            />
          </Box>

          <Box mt={2} style={{ margin: 10 }}>
            <SimpleSearchControl
              label="Product"
              labelOverride="Product"
              searchFormType="Products"
              defaultValue={values.product}
              value={values.product}
              setSelectedValue={(e) => {
                setFieldValue('Product', e);

                if (originProduct === e && values.density !== 0) {
                  return;
                }

                setOriginalProduct(e);

                const supDenRow = supplierDensityRows.filter(
                  (x) => x.product === e
                );
                if (supDenRow.length > 0) {
                  setFieldValue('density', supDenRow[0].density);
                } else {
                  setFieldValue('density', 0);
                }
              }} // {setSupplyPoint}
              // setSelectedValue={(e) =>  setSelectedProduct(e)} // {setSupplyPoint}
              descriptionColumn="description"
              showDescriptionInLabel
            />
          </Box>

          <Box mt={2} style={{ margin: 10 }}>
            <TextField
              error={Boolean(touched.sfl && errors.sfl)}
              fullWidth
              helperText={touched.sfl && errors.sfl}
              label="SFL"
              name="sfl"
              disabled
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.sfl}
              type="Number"
              variant="outlined"
            />
          </Box>

          <Box mt={2} style={{ margin: 10 }}>
            <TextField
              error={Boolean(touched.density && errors.density)}
              fullWidth
              helperText={touched.density && errors.density}
              label="Density"
              name="density"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.density}
              type="Number"
              variant="outlined"
            />
          </Box>

          <Box mt={2} style={{ margin: 10 }}>
            <TextField
              error={Boolean(touched.volume && errors.volume)}
              fullWidth
              helperText={touched.volume && errors.volume}
              label="Volume"
              name="volume"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.volume}
              type="Number"
              variant="outlined"
            />
          </Box>

          <Divider />
          <Box p={2} display="flex" alignItems="center">
            {mode === 'deleteEnabled' && (
              <IconButton onClick={() => onDelete(values.id)}>
                <SvgIcon>
                  <TrashIcon />
                </SvgIcon>
              </IconButton>
            )}

            <Box flexGrow={1} />

            <Button
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              color="secondary"
              className={classes.confirmButton}
            >
              Confirm
            </Button>

            <Button onClick={onCancel}>Cancel</Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

AddEditForm.propTypes = {
  row: PropTypes.object,
  mode: PropTypes.oneOf(['add', 'edit']).isRequired,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  supplierDensityRows: PropTypes.array,
};

AddEditForm.defaultProps = {
  row: {},
  onSave: () => {},
  onCancel: () => {},
  onDelete: () => {},
  supplierDensityRows: [],
};

export default AddEditForm;
