/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
import jwtDecode from 'jwt-decode';
import axios from 'src/utils/axios';
import moment from 'moment';

class AuthService {
  setAxiosInterceptors = () => {
    axios.interceptors.response.use(
      (res) => res,
      async (err) => {
        const originalConfig = err.config;
        if (err.response) {
          // Access Token was expired
          if (err.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;
            try {
              const loggedInUser = JSON.parse(this.getLoggedInUser());
              if (!loggedInUser) {
                window.location.reload();
                return Promise.reject(err);
              }
              const response = await axios.post('api/user/refreshToken', '', {
                headers: {
                  RefreshAuth: `Basic ${btoa(
                    `${loggedInUser.id}:${loggedInUser.refreshToken}`
                  )}`,
                },
              });
              const { data } = response;
              if (data) {
                this.setSession(data.token);
                localStorage.setItem('accessToken', data.token);
                localStorage.setItem('loggedInUser', JSON.stringify(data));
                err.config.headers.Authorization = `Bearer ${data.token}`;
                err.config.baseURL = undefined;

                localStorage.setItem('accessToken', data.token);
                return axios.request(err.config);
              }
            } catch (_error) {
              if (_error?.response?.status === 400) {
                this.setSession(null);
              }
              return Promise.reject(_error);
            }
          }
        }

        return Promise.reject(err);
      }
    );
  };

  loginWithEmailAndPassword = (email, password) =>
    new Promise((resolve, reject) => {
      axios
        .post('api/user/login', '', {
          headers: {
            Authorization: `Basic ${btoa(`${email}:${password}`)}`,
          },
        })
        .then((response) => {
          const { data } = response;
          if (!data) {
            reject(new Error('Invalid Username or Password'));
          }

          if (data) {
            this.setSession(data.token);
            localStorage.setItem('accessToken', data.token);
            localStorage.setItem('loggedInUser', JSON.stringify(data));
            resolve(data);
          } else {
            reject(data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  loginInWithToken = () =>
    new Promise((resolve, reject) => {
      const loggedInUser = JSON.parse(this.getLoggedInUser());

      if (!loggedInUser) {
        reject(new Error('Expired Token'));
      }

      resolve(loggedInUser);
    });

  logout = () => {
    this.setSession(null);
  };

  setSession = (accessToken) => {
    if (accessToken) {
      axios.defaults.headers.post['Content-Type'] = 'application/json';
      axios.defaults.headers.put['Content-Type'] = 'application/json';
      this.setCacheExpiry();
    } else {
      localStorage.removeItem('loggedInUser');
      localStorage.removeItem('accessToken');
    }
  };

  setCacheExpiry = () => {
    const expiry = JSON.parse(localStorage.getItem('cacheExpiry'));
    if (expiry && moment(expiry) > moment()) {
      return;
    }

    localStorage.setItem(
      'cacheExpiry',
      JSON.stringify(moment().add(15, 'minutes').format())
    );
  };

  getAccessToken = async () => {
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers.put['Content-Type'] = 'application/json';
    return localStorage.getItem('accessToken');
  };

  getLoggedInUser = () => localStorage.getItem('loggedInUser');

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  };

  isAuthenticated = async () => !!this.getAccessToken();
}

const authService = new AuthService();

export default authService;
