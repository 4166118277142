import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import useSettings from 'src/hooks/useSettings';
import Auth from 'src/components/Auth';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OfflineMutations from 'src/store/OfflineMutations';
import Routes from './routes';
import { createMyTheme } from './theme';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24 // 24 hours
    }
  }
});

const persister = createSyncStoragePersister({
  storage: window.localStorage
});

function App() {
  const settings = useSettings();

  return (
    <ThemeProvider theme={createMyTheme(settings)}>
      <ToastContainer />
      <OfflineMutations queryClient={queryClient} />
      <SnackbarProvider maxSnack={1}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <PersistQueryClientProvider
            client={queryClient}
            persistOptions={{ persister }}
            onSuccess={() => {
              queryClient.resumePausedMutations().then(() => {
                queryClient.invalidateQueries();
              });
            }}
          >
            <BrowserRouter>
              <Auth>
                <Routes />
              </Auth>
            </BrowserRouter>
          </PersistQueryClientProvider>
        </LocalizationProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
