import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import ProfileDetails from './ProfileDetails';
import GeneralSettings from './GeneralSettings';

function General({ className, ...rest }) {
  const { user } = useSelector((state) => state.account);

  return (
    <Grid className={className} container spacing={3} {...rest}>
      <Grid item lg={4} md={6} xl={3} xs={12}>
        <ProfileDetails user={user} />
      </Grid>
      <Grid item lg={8} md={6} xl={9} xs={12}>
        <GeneralSettings user={user} />
      </Grid>
    </Grid>
  );
}

General.propTypes = {
  className: PropTypes.string,
};

export default General;
