import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
} from '@mui/material';
import Page from 'src/components/Page';
import Logo from 'src/components/Logo';
import { makeStyles } from 'tss-react/mui';
import RegisterForm from './RegisterForm';

const useStyles = makeStyles()((theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    height: '100%',
    minHeight: '100vh',
    flexDirection: 'column',
  },
}));

function ResetPasswordView() {
  const { classes } = useStyles();
  const history = useNavigate();

  const handleSubmitSuccess = () => {
    history('/login');
  };

  return (
    <Page className={classes.root} title="Reset Password">
      <Container maxWidth="sm">
        <Box mb={5} display="flex" alignItems="center">
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          <Button
            component={RouterLink}
            to="/"
            className={classes.backButton}
            color="secondary"
          >
            Back to home
          </Button>
        </Box>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h2" color="textPrimary">
              Forgot Password
            </Typography>
            <Box mt={3}>
              <RegisterForm onSubmitSuccess={handleSubmitSuccess} />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}

export default ResetPasswordView;
