/* eslint-disable no-console */
import React, { useEffect, useState, useRef } from 'react';
import {
  Autocomplete,
  TextField,
  Box,
  Popper,
  Divider,
  CircularProgress,
  Typography,
  FormControl,
  FormHelperText,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import axios from 'src/utils/axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import _ from 'lodash';

function SimpleSearchControl({
  searchFormType,
  defaultValue,
  setSelectedRow,
  setSelectedValue,
  descriptionColumn,
  labelPrefix,
  error,
  helperText,
  disabled,
  autoFocus,
  labelOverride,
  ...rest
}) {
  const account = useSelector((state) => state.account);

  const getLabel = () => {
    if (
      searchFormType === 'Site' &&
      account?.user?.fmsCustomer &&
      account?.user?.fmsCustomer !== ''
    ) {
      return _.startCase(`${labelPrefix ?? ''} Tank`);
    }

    return _.startCase(
      labelOverride ?? '' != ''
        ? `${labelOverride}`
        : `${labelPrefix ?? ''} ${searchFormType.replace('HideFMS', '')}`
    );
  };

  const [label, setLabel] = useState(getLabel());
  const [searchValue, setSearchValue] = useState(defaultValue ?? null);
  const [searchingValue, setSearchingValue] = useState(defaultValue ?? null);

  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const [columns, setColumns] = useState([]);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [blured, setBlured] = useState(false);
  const [siteDisabled, setSiteDisabled] = useState(false);
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const [, setError] = useState(false);

  const [primaryKey, setPrimaryKey] = useState(null);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();

      setTimeout(() => {
        inputRef.current.select();
      }, 100);
    }
  }, [inputRef]);

  useEffect(() => {
    setSearchValue(defaultValue);
    setSearchingValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    const searchEndPoint = `/api/search/columns/${searchFormType}`;

    axios
      .get(searchEndPoint)
      .then((response) => {
        setColumns(response.data.filter((x) => !x.hidden));
        setPrimaryKey(
          (response.data[0]?.name ?? '').substring(0, 1).toLowerCase() +
            (response.data[0]?.name ?? '').substring(
              1,
              response.data[0]?.name?.length ?? 1
            )
        );

        if (
          searchFormType === 'Site' &&
          account?.user?.site &&
          account?.user?.site !== -1
        ) {
          setSiteDisabled(true);
          setSearchValue(account?.user?.site);
          setSearchingValue(account?.user?.site);
        }
      })
      .catch((errorReturn) => {
        console.log(errorReturn);
      });
  }, [searchFormType]);

  useEffect(() => {
    const loadData = () => {
      if (columns.length === 0 && !defaultValue) {
        return;
      }

      setLoading(true);
      axios
        .get(`/api/search/${searchFormType}`, {
          params: {
            searchString: searchingValue ?? '%',
          },
        })
        .then((res) => {
          setOptions(res.data);
          setLoading(false);
        })
        .catch((errorReturn) => {
          console.log(errorReturn);
        });
    };

    const timerId = setTimeout(loadData, 300);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchingValue, open]);

  useEffect(() => {
    if (descriptionColumn && searchValue && searchValue[descriptionColumn]) {
      setLabel(`${getLabel()} - ${searchValue[descriptionColumn]}`);
    } else {
      setLabel(getLabel());
    }
  }, [searchValue]);

  function getValue(option, columnName) {
    const passedValue = option[columnName];

    if (passedValue === null) {
      return '';
    }

    if (
      columnName.toLowerCase().indexOf('date') !== -1 &&
      moment(passedValue).isValid()
    ) {
      return moment(passedValue).format('DD/MM/YYYY HH:mm');
    }

    return passedValue;
  }

  useEffect(() => {
    if ((open && !blured) || !searchingValue) {
      return;
    }

    const selectedOption = options.filter(
      (x) =>
        x[primaryKey].toString().toLowerCase() ===
        searchingValue?.toString().toLowerCase()
    );

    if (selectedOption.length > 0) {
      if (searchValue !== selectedOption[0]) {
        setSearchValue(selectedOption[0]);

        if (setSelectedRow) {
          setSelectedRow(selectedOption[0]);
        }

        if (setSelectedValue) {
          setSelectedValue(selectedOption[0][primaryKey]);
        }
        setError(false);
      }
    } else {
      setSearchValue(null);
      setError(true);

      if (setSelectedValue && !searchingValue) {
        setSelectedValue('');
      }
    }
  }, [options, blured]);

  const getMaxCol = () => {
    if (!mobileDevice || columns.length < 4) {
      return columns.length;
    }

    return 4;
  };

  return (
    <Autocomplete
      ref={autoCompleteRef}
      value={searchValue}
      freeSolo
      blurOnSelect
      selectOnFocus
      onChange={(e, v) => {
        setSearchValue(v);
        setSearchingValue(v ? v[primaryKey] : '');

        if (setSelectedRow) {
          setSelectedRow(v);
        }

        if (setSelectedValue) {
          if (v) {
            setSelectedValue(v[primaryKey]);
          } else {
            setSelectedValue('');
          }
        }

        if (v) {
          const currentElement = autoCompleteRef.current;
          let nextElement = currentElement.parentElement.nextElementSibling;

          if (!nextElement) {
            nextElement =
              currentElement.parentElement.parentElement.nextElementSibling;
          }

          if (nextElement) {
            const nextInput = nextElement.querySelector('input');

            if (nextInput) {
              nextInput.focus();
              nextInput.select();
            }
          }
        }

        setError(false);
      }}
      onInputChange={(e) => {
        setSearchingValue(e?.target?.value);
      }}
      options={options}
      open={open}
      filterOptions={(options) => options}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      {...rest}
      disabled={disabled || siteDisabled}
      onBlur={() => setBlured(true)}
      onFocus={() => setBlured(false)}
      getOptionLabel={(option) => (option[primaryKey] ?? option).toString()}
      renderOption={(props, option) => (
        <div key={option[primaryKey]}>
          <Box
            {...props}
            component="li"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              p: 1,
              pl: 2,
              pr: 2,
            }}
          >
            {columns?.slice(0, getMaxCol()).map((column) => (
              <Box
                key={column.name}
                sx={{
                  width: `${100 / getMaxCol()}%`,
                  textAlign: column.rightAlign ? 'right' : 'left',
                }}
              >
                <Typography variant="body2">
                  {getValue(option, column.lookupName)}
                </Typography>
              </Box>
            ))}
          </Box>
          <Divider />
        </div>
      )}
      PopperComponent={({ children, ...props }) => (
        <Popper style={{}} {...props}>
          {!searchingValue ||
          searchingValue.length === 0 ||
          options.length === 0 ? null : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  p: 1,
                  pl: 2,
                  bgcolor: '#E5E7E9',
                  paddingRight: '35px',
                }}
              >
                {columns?.slice(0, getMaxCol()).map((column) => (
                  <Box
                    key={column.name}
                    sx={{
                      width: `${100 / getMaxCol()}%`,
                      textAlign: column.rightAlign ? 'right' : 'left',
                    }}
                  >
                    <strong> {_.startCase(column.name)}</strong>
                  </Box>
                ))}
              </Box>
              {children}
            </>
          )}
        </Popper>
      )}
      renderInput={(params) => (
        <FormControl fullWidth>
          <TextField
            {...params}
            variant="outlined"
            error={error}
            label={label}
            inputRef={inputRef}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
          {helperText ? (
            <FormHelperText style={{ color: error ? 'red' : '' }}>
              {helperText}
            </FormHelperText>
          ) : null}
        </FormControl>
      )}
    />
  );
}

SimpleSearchControl.propTypes = {
  searchFormType: PropTypes.string,
  defaultValue: PropTypes.any,
  setSelectedRow: PropTypes.func,
  setSelectedValue: PropTypes.func,
  descriptionColumn: PropTypes.string,
  labelPrefix: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  labelOverride: PropTypes.string,
};

export default SimpleSearchControl;
