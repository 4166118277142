import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  SvgIcon,
  TextField,
  InputAdornment,
  Autocomplete,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Menu as MenuIcon, Search as SearchIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import LogoSmall from 'src/components/OCLogo';
import { THEMES } from 'src/constants';
import { useSelector } from 'react-redux';
import Account from './Account';

const programs = [
  {
    program: 'Load Manager',
    url: '/app/loadManager',
  },
  {
    program: 'Users',
    url: '/app/management/users',
  },
];

const useStyles = makeStyles()((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main,
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default,
        }
      : {}),
  },
  toolbar: {
    minHeight: 64,
  },
  input: {
    color: 'black',
  },
}));

function TopBar({ className, onMobileNavOpen, ...rest }) {
  const { classes } = useStyles();
  const history = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const { isFMSCustomer } = useSelector((state) => state.account);

  const options = programs
    .filter((x) => !x.hideIfFMS || !isFMSCustomer)
    .map((option) => {
      let path = option.module;
      if (option.submodule) {
        path += ` > ${option.submodule}`;
      }

      if (isFMSCustomer) {
        // eslint-disable-next-line no-param-reassign
        option.program = option.program.replace('Site', 'Tank');
      }

      const fullPath = `${path} > ${option.program}`;
      return {
        path,
        fullPath,
        ...option,
      };
    });

  const search = (e, searchParam) => {
    if (!searchParam.url) {
      return null;
    }

    const query = new URLSearchParams(window.location.search);
    query.append('return', searchParam.url);
    return history(`/login?${query.toString()}`);
  };

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
          <LogoSmall style={{ height: 30 }} />
        </Hidden>

        <Hidden mdDown>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </Hidden>

        <Box ml={2} flexGrow={1} />

        <Autocomplete
          style={{ width: 300, backgroundColor: '#FFFFFF', borderRadius: 5 }}
          freeSolo
          id="search"
          size="small"
          autoHighlight
          disableClearable
          options={options.sort(
            (a, b) => -b.fullPath.localeCompare(a.fullPath)
          )}
          groupBy={(option) => option.path}
          getOptionLabel={(option) => option?.program ?? ''}
          onChange={search}
          value={searchValue ?? ''}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              placeholder="Search"
              onChange={(e, v) => setSearchValue(v)}
              InputProps={{
                ...params.InputProps,
                type: 'search',
                className: classes.input,
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" style={{ color: 'Grey' }}>
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />

        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
