/* eslint-disable react/no-array-index-key */
import React, { Suspense, Fragment } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

import LoginView from 'src/views/auth/LoginView';
import Error404View from 'src/views/Pages/Error404View';
import UserListView from 'src/views/UserListView';
import LoadManagerView from 'src/views/LoadManagerView';

import AccountView from 'src/views/Pages/AccountView';
import VerificationView from 'src/views/auth/VerificationView';
import ResetPasswordView from 'src/views/auth/ResetPasswordView';

const routesConfig = [
  {
    exact: true,
    path: '/',
    element: <Navigate to="/login" replace />,
  },
  {
    exact: true,
    path: '/404',
    element: <Error404View />,
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    element: <LoginView />,
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/verification/:id/:token',
    element: <VerificationView />,
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/resetPassword',
    element: <ResetPasswordView />,
  },
  {
    exact: true,
    guard: AuthGuard,
    layout: MainLayout,
    path: '/app',
    element: <Navigate to="/app/loadManager" replace />,
  },
  {
    exact: true,
    guard: AuthGuard,
    layout: MainLayout,
    path: '/app/loadManager',
    element: <LoadManagerView />,
  },
  {
    exact: true,
    guard: AuthGuard,
    layout: MainLayout,
    path: '/app/management/users',
    element: <UserListView />,
  },
  {
    exact: true,
    guard: AuthGuard,
    layout: MainLayout,
    path: '/app/account',
    element: <AccountView />,
  },
  {
    path: '*',
    element: <Navigate to="/404" replace />,
  },
];

const renderRoutes = (routes) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Element = route.element;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              element={
                <Guard>
                  <Layout>{Element}</Layout>
                </Guard>
              }
            />
          );
        })}
      </Routes>
    </Suspense>
  ) : null;

function RenderedRoutes() {
  return renderRoutes(routesConfig);
}

export default RenderedRoutes;
