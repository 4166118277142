import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

function AuthGuard({ children }) {
  const account = useSelector((state) => state.account);

  if (!account.user) {
    if (window.location.pathname === '/app/loadManager') {
      return <Navigate to="/login" replace />;
    }

    const query = new URLSearchParams(window.location.search);
    query.append('return', window.location.pathname);
    return <Navigate to={`/login?${query.toString()}`} replace />;
  }

  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.any,
};

export default AuthGuard;
