import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'src/utils/axios';
import PropTypes from 'prop-types';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useSnackbar } from 'notistack';
import { date } from 'yup';
import AddEditModal from './AddEditModal';

function createData(product, density) {
  return { product, density };
}

function SupplierDensities({
  loadPlan,
  supplier,
  loadPlanDate,
  compData,
  supplierDensityRows,
  setSupplierDensityRows,
  refreshCompartmentGrid,
  setrefreshCompartmentGrid,
  ...rest
}) {
  //  const TruckUtilisation =forwardRef((props, ref) => {
  // Should I use the UseEffect rather than do rows2 function??
  const [axleResults, setAxleResults] = useState(null); // look at setUsers
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();

  const [editingId, setEditingId] = useState('');
  const [addEditOpen, setAddEditOpen] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);

  // Load saved axle weights for load plan
  useEffect(() => {
    if (loadPlan !== '') {
      const endPoint = `/api/supplier/supplierDensities/${loadPlan}`;

      axios
        .get(endPoint)
        .then((response) => {
          if (isMountedRef.current) {
            console.log(response.data);
            setSupplierDensityRows(response.data);
          }
        })
        .catch((error) => {
          enqueueSnackbar(error?.response?.data?.message ?? error.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        });
    }
  }, [loadPlan, supplier, loadPlanDate, compData]);

  const closeAddEditDialog = () => {
    setAddEditOpen(false);
    setEditingId('');
  };

  const getData = () => {
    if (loadPlan !== '') {
      const endPoint = `/api/supplier/supplierDensities/${loadPlan}`;

      axios
        .get(endPoint)
        .then((response) => {
          if (isMountedRef.current) {
            setSupplierDensityRows(response.data);
            // compartmentUpdated();
          }
        })
        .catch((error) => {
          enqueueSnackbar(error?.response?.data?.message ?? error.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        });
    }
  };

  const save = (newData) => {
    setBackdropOpen(true);

    axios
      .post(
        `/api/supplier/supplierDensities/${loadPlan}`,
        JSON.stringify(newData)
      )
      .then(() => {
        setrefreshCompartmentGrid(!refreshCompartmentGrid);
        console.log('refreshcompgridval');
        console.log(refreshCompartmentGrid);
      })
      .then(() => {
        setBackdropOpen(false);
        getData();
      })
      .catch((error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        setBackdropOpen(false);
      });
  };

  const handleAddEditSave = (event) => {
    if (
      editingId === '' &&
      compData.filter((x) => x.id === event.id).length > 0
    ) {
      enqueueSnackbar('Id already exists', {
        variant: 'error',
        autoHideDuration: 3000,
      });

      // if L15 density has changed and we have compartments with that product alert do ou want to update
      // alert("Update Compartment densities for product xyz");
      return;
    }

    setAddEditOpen(false);
    setEditingId('');
    save(event);
  };

  const handleAddEditDelete = () => {
    setAddEditOpen(false);
    setEditingId('');
  };

  return (
    <TableContainer component={Paper} {...rest}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell align="right">L15 Density</TableCell>
            <TableCell align="right">Temperature</TableCell>
            <TableCell align="right">AMB Density</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {supplierDensityRows
            .filter((x) => x.onLoadPlan)
            .map((row) => (
              <TableRow
                hover
                tabIndex={-1}
                key={row.product}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                onClick={() => {
                  setEditingId(row.product);
                  setAddEditOpen(true);
                }}
              >
                <TableCell component="th" scope="row">
                  {row.product}
                </TableCell>
                <TableCell align="right">{row.l15Density}</TableCell>
                <TableCell align="right">{row.temperature}</TableCell>
                <TableCell align="right">{row.density}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <AddEditModal
        row={supplierDensityRows?.filter((x) => x.product === editingId)[0]}
        mode={editingId === '' ? 'add' : 'edit'}
        onSave={handleAddEditSave}
        onCancel={closeAddEditDialog}
        onDelete={handleAddEditDelete}
        open={addEditOpen}
      />
    </TableContainer>
  );
}

SupplierDensities.propTypes = {
  loadPlan: PropTypes.string,
  supplier: PropTypes.string,
  loadPlanDate: PropTypes.instanceOf(date),
  compData: PropTypes.any,
  supplierDensityRows: PropTypes.array,
  setSupplierDensityRows: PropTypes.func,
};
export default SupplierDensities;
