import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'src/utils/axios';
import PropTypes from 'prop-types';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useSnackbar } from 'notistack';

function createData(axle, maxWeight, weight, tare) {
  return { axle, maxWeight, weight, tare };
}

function TruckUtilisation({ loadPlan, compData, ...rest }) {
  //  const TruckUtilisation =forwardRef((props, ref) => {
  // Should I use the UseEffect rather than do rows2 function??
  const [axleResults, setAxleResults] = useState(null); // look at setUsers
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const [rows, setRows] = useState([
    // createData('1', 159, 6.0, 24, 4.0),
    // createData('2', 237, 9.0, 37, 4.3),
    // createData('3', 262, 16.0, 24, 6.0),
    // createData('4', 305, 3.7, 67, 4.3),
  ]);

  // Load saved axle weights for load plan
  useEffect(() => {
    if (loadPlan !== '') {
      const endPoint = `/api/LoadPlanController/loadPlanAxleWeights/${loadPlan}`;

      axios
        .get(endPoint)
        .then((response) => {
          if (isMountedRef.current) {
            setRows(response.data);
          }
        })
        .catch((error) => {
          enqueueSnackbar(error?.response?.data?.message ?? error.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        });
    }
  }, [loadPlan, compData]);

  return (
    <TableContainer component={Paper} {...rest}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Axle</TableCell>
            <TableCell align="right">Max Weight</TableCell>
            <TableCell align="right">Weight</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.axle}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.axle}
              </TableCell>
              <TableCell align="right">
                {row.maxWeight.toLocaleString()}
              </TableCell>
              <TableCell align="right">{row.weight.toLocaleString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

TruckUtilisation.propTypes = {
  loadPlan: PropTypes.string,
  compData: PropTypes.any,
};
export default TruckUtilisation;
