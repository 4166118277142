import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Button, Grid, SvgIcon, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Download as DownloadIcon } from 'react-feather';

const useStyles = makeStyles()((theme) => ({
  root: {
    marginTop: 10
  },
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  },
  backdrop: {
    zIndex: theme.zIndex.tooltip + 1,
    color: '#fff'
  }
}));

function Header({ className, exportGrid, ...rest }) {
  const { classes } = useStyles();

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      justify="space-between"
      spacing={3}
      {...rest}
    >
      <Grid container direction="column" alignItems="center" justify="center">
        <Typography variant="h3" color="textPrimary">
          All Users
        </Typography>
        <Box mt={2}>
          <Button className={classes.action} onClick={exportGrid}>
            <SvgIcon fontSize="small" className={classes.actionIcon}>
              <DownloadIcon />
            </SvgIcon>
            Export
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  exportGrid: PropTypes.func
};

export default Header;
