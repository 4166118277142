import React, { useRef } from 'react';
import { Box, Container } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Page from 'src/components/Page';
import Header from './Header';
import Results from './Results';

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    minHeight: '95vh',
    flexDirection: 'column'
  }
}));

function UserListView() {
  const { classes } = useStyles();
  const organisationRef = useRef();

  const exportGrid = () => {
    organisationRef.current.exportGrid();
  };

  return (
    <Page className={classes.root} title="User List">
      <Container maxWidth={false}>
        <Header exportGrid={exportGrid} />
        <Box mt={3}>
          <Results ref={organisationRef} />
        </Box>
      </Container>
    </Page>
  );
}

export default UserListView;
