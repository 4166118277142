import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  TextField,
  Typography,
  Link,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import axios from 'src/utils/axios';
import { makeStyles } from 'tss-react/mui';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles()((theme) => ({
  root: {},
  backdrop: {
    zIndex: theme.zIndex.tooltip + 1,
    color: '#fff',
  },
}));

function RegisterForm({ className, data, onSubmitSuccess, ...rest }) {
  const { classes } = useStyles();
  const [backdropOpen, setBackdropOpen] = useState(false);
  const { id, token } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <div>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Formik
        initialValues={{
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phoneNumber: data.phoneNumber,
          password: '',
          confirmPassword: '',
          policy: false,
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().max(255).required('First name is required'),
          lastName: Yup.string().max(255).required('Last name is required'),
          email: Yup.string()
            .email('Must be a valid email')
            .max(255)
            .required('Email is required'),
          password: Yup.string()
            .min(7)
            .max(255)
            .required('Password is required'),
          confirmPassword: Yup.string()
            .min(7)
            .max(255)
            .oneOf([Yup.ref('password'), null], "Passwords don't match")
            .required('Confirm Password is required'),
          policy: Yup.boolean().oneOf([true], 'This field must be checked'),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            setBackdropOpen(true);
            const updatedValues = values;
            updatedValues.userId = id;
            updatedValues.token = token;

            axios.defaults.headers.post['Content-Type'] = 'application/json';
            axios
              .post('/api/verification', JSON.stringify(updatedValues))
              .then(() => {
                setBackdropOpen(false);
                enqueueSnackbar('Success', {
                  variant: 'success',
                });
                setTimeout(() => {
                  onSubmitSuccess();
                }, 1000);
              })
              .catch((error) => {
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
                setBackdropOpen(false);
              });
          } catch (error) {
            setStatus({ success: false });
            setErrors({ submit: error.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
          >
            <TextField
              error={Boolean(touched.firstName && errors.firstName)}
              fullWidth
              helperText={touched.firstName && errors.firstName}
              label="First Name"
              margin="normal"
              name="firstName"
              onBlur={handleBlur}
              onChange={handleChange}
              type="firstName"
              value={values.firstName}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.lastName && errors.lastName)}
              fullWidth
              helperText={touched.lastName && errors.lastName}
              label="Last Name"
              margin="normal"
              name="lastName"
              onBlur={handleBlur}
              onChange={handleChange}
              type="lastName"
              value={values.lastName}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label="Email Address"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              type="email"
              value={values.email}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.phoneNumber && errors.phoneNumber)}
              fullWidth
              helperText={touched.phoneNumber && errors.phoneNumber}
              label="Phone Number"
              margin="normal"
              name="phoneNumber"
              onBlur={handleBlur}
              onChange={handleChange}
              type="phoneNumber"
              value={values.phoneNumber}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              fullWidth
              helperText={touched.confirmPassword && errors.confirmPassword}
              label="Confirm Password"
              margin="normal"
              name="confirmPassword"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.confirmPassword}
              variant="outlined"
            />
            <Box alignItems="center" display="flex" mt={2} ml={-1}>
              <Checkbox
                checked={values.policy}
                name="policy"
                onChange={handleChange}
              />
              <Typography variant="body2" color="textSecondary">
                I have read the{' '}
                <Link
                  component="a"
                  href="https://companionsoftware.com.au/privacy-policy/"
                  color="secondary"
                >
                  Terms and Conditions
                </Link>
              </Typography>
            </Box>
            {Boolean(touched.policy && errors.policy) && (
              <FormHelperText error>{errors.policy}</FormHelperText>
            )}
            <Box mt={2}>
              <Button
                color="secondary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {data.emailConfirmed ? 'Reset' : 'Verify'}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </div>
  );
}

RegisterForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
  data: PropTypes.object,
};

RegisterForm.default = {
  onSubmitSuccess: () => {},
};

export default RegisterForm;
