/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Grid,
  Typography,
  Link,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import axios from 'src/utils/axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import AddEditModal from './AddEditModal';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'comp',
    label: 'Comp',
    align: 'left',
  },
  {
    id: 'product',
    label: 'Product',
    align: 'left',
  },
  {
    id: 'sfl',
    label: 'SFL',
    align: 'right',
  },
  {
    id: 'density',
    label: 'Density',
    align: 'right',
  },
  {
    id: 'volume',
    label: 'Volume',
    align: 'right',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function LoadGrid({
  loadPlan,
  compData,
  setCompData,
  supplierDensityRows,
  refreshCompartmentGrid,
  ...rest
}) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('comp');
  const [editingId, setEditingId] = useState('');
  const [addEditOpen, setAddEditOpen] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const closeAddEditDialog = () => {
    setAddEditOpen(false);
    setEditingId('');
  };

  const getData = () => {
    if (loadPlan !== '') {
      const endPoint = `/api/LoadPlanController/loadPlanCompartments/${loadPlan}`;

      axios
        .get(endPoint)
        .then((response) => {
          if (isMountedRef.current) {
            setCompData(response.data);
            // compartmentUpdated();
          }
        })
        .catch((error) => {
          enqueueSnackbar(error?.response?.data?.message ?? error.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        });
    }
  };

  const save = (newData) => {
    setBackdropOpen(true);

    axios
      .post(
        `/api/loadPlan/loadPlanCompartments/${loadPlan}`,
        JSON.stringify(newData)
      )
      .then(() => {
        setBackdropOpen(false);
        getData();
      })
      .catch((error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        setBackdropOpen(false);
      });
  };

  const handleAddEditSave = (event) => {
    if (
      editingId === '' &&
      compData.filter((x) => x.id === event.id).length > 0
    ) {
      enqueueSnackbar('Id already exists', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return;
    }

    setAddEditOpen(false);
    setEditingId('');
    save(event);
  };

  const handleAddEditDelete = () => {
    setAddEditOpen(false);
    setEditingId('');
  };

  // Load saved compartment data for loadplan
  useEffect(() => {
    console.log('About to get compartmentdata');
    console.log('refresh compdata ' + refreshCompartmentGrid);
    console.log('loadPlan ' + loadPlan);
    getData();
  }, [loadPlan, refreshCompartmentGrid]);

  return (
    <Grid {...rest}>
      <Backdrop style={{ zIndex: 9999, color: '#fff' }} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper>
        <TableContainer style={{}}>
          <Table stickyHeader>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            {!compData ? null : (
              <TableBody>
                {stableSort(compData, getComparator(order, orderBy)).map(
                  (row) => (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.id}
                      onClick={() => {
                        setEditingId(row.id);
                        setAddEditOpen(true);
                      }}
                    >
                      <TableCell>
                        <Link
                          component={RouterLink}
                          underline="none"
                          to="#"
                          style={{ textDecoration: 'none' }}
                        >
                          <Typography variant="body2">{row.id}</Typography>
                        </Link>
                      </TableCell>

                      <TableCell>
                        <Link
                          component={RouterLink}
                          underline="none"
                          to="#"
                          style={{ textDecoration: 'none' }}
                        >
                          <Typography variant="body2">{row.product}</Typography>
                        </Link>
                      </TableCell>

                      <TableCell align="right">
                        <Link
                          component={RouterLink}
                          underline="none"
                          to="#"
                          style={{ textDecoration: 'none' }}
                        >
                          <Typography variant="body2">
                            {row.sfl.toLocaleString()}
                          </Typography>
                        </Link>
                      </TableCell>

                      <TableCell align="right">
                        <Link
                          component={RouterLink}
                          underline="none"
                          to="#"
                          style={{ textDecoration: 'none' }}
                        >
                          <Typography variant="body2">{row.density}</Typography>
                        </Link>
                      </TableCell>

                      <TableCell align="right">
                        <Link
                          component={RouterLink}
                          underline="none"
                          to="#"
                          style={{ textDecoration: 'none' }}
                        >
                          <Typography variant="body2">
                            {row.volume.toLocaleString()}
                          </Typography>
                        </Link>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <AddEditModal
          row={compData?.filter((x) => x.id === editingId)[0]}
          mode={editingId === '' ? 'add' : 'edit'}
          onSave={handleAddEditSave}
          onCancel={closeAddEditDialog}
          onDelete={handleAddEditDelete}
          open={addEditOpen}
          supplierDensityRows={supplierDensityRows}
        />
      </Paper>
    </Grid>
  );
}

LoadGrid.propTypes = {
  compData: PropTypes.array,
  setCompData: PropTypes.func,
  loadPlan: PropTypes.string,
  supplierDensityRows: PropTypes.array,
  refreshCompartmentGrid: PropTypes.bool,
};

export default LoadGrid;
