import CustomerTypes from './CustomerTypes';

function OfflineMutations({ queryClient }) {
  queryClient.setMutationDefaults(
    ['updateCustomerTypes'],
    CustomerTypes(queryClient)
  );
}

export default OfflineMutations;
