import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@mui/material';
import AddEditNewLoadPlanForm2 from './AddEditForm';

function AddEditNewLoadPlanModal({
  row,
  mode,
  onSave,
  onCancel,
  // onDelete,
  open,
  ...rest
}) {
  return (
    <Dialog maxWidth="sm" fullWidth onClose={onCancel} open={open} {...rest}>
      {/* Dialog renders its body even if not open */}
      {open && (
        <AddEditNewLoadPlanForm2
          row={row}
          mode={mode}
          onSave={onSave}
          onCancel={onCancel}
          // onDelete={onDelete}
        />
      )}
    </Dialog>
  );
}

AddEditNewLoadPlanModal.propTypes = {
  row: PropTypes.object,
  mode: PropTypes.oneOf(['add', 'edit']),
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  // onDelete: PropTypes.func,
  open: PropTypes.bool,
};

AddEditNewLoadPlanModal.defaultProps = {
  row: {},
  onSave: () => {},
  onCancel: () => {},
  // onDelete: () => {},
  open: false,
};

export default AddEditNewLoadPlanModal;
