import React, { createContext, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { THEMES } from 'src/constants';
import { storeSettings } from 'src/utils/settings';

const initialState = {
  direction: 'ltr',
  responsiveFontSizes: true,
  hideQueueInDetail: false,
  reverseCommentOrder: false,
  theme: THEMES.LIGHT,
  setTheme: () => {}
};

const SettingsContext = createContext(initialState);

export function SettingsProvider({ settings, children }) {
  const [currentSettings, setCurrentSettings] = useState(
    settings || initialState
  );

  const handleSaveSettings = (updatedSettings = {}) => {
    const mergedSettings = _.merge({}, currentSettings, updatedSettings);

    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  const option = useMemo(
    () => ({
      settings: currentSettings,
      saveSettings: handleSaveSettings
    }),
    [currentSettings]
  );

  useEffect(() => {
    document.dir = currentSettings.direction;
  }, [currentSettings]);

  return (
    <SettingsContext.Provider value={option}>
      {children}
    </SettingsContext.Provider>
  );
}

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  settings: PropTypes.object
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
