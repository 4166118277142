import React, { useState } from 'react';
import { Box, Container, Divider, Tab, Tabs } from '@mui/material';
import Page from 'src/components/Page';
import { makeStyles } from 'tss-react/mui';
import Header from './Header';
import General from './General';
import Security from './Security';

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    minHeight: '95vh',
    flexDirection: 'column',
  },
}));

function AccountView() {
  const { classes } = useStyles();
  const [currentTab, setCurrentTab] = useState('general');
  const tabs = [
    { value: 'general', label: 'General' },
    { value: 'security', label: 'Security' },
  ];

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  return (
    <Page className={classes.root} title="Settings">
      <Container maxWidth="lg" style={{ paddingTop: 10 }}>
        <Header />
        <Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
            className={classes.tabs}
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          {currentTab === 'general' && <General />}{' '}
          {currentTab === 'security' && <Security />}
        </Box>
      </Container>
    </Page>
  );
}

export default AccountView;
