/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useEffect } from 'react';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Grid,
} from '@mui/material';
import Page from 'src/components/Page';
import Logo from 'src/components/Logo';
import axios from 'src/utils/axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { makeStyles } from 'tss-react/mui';
import RegisterForm from './RegisterForm';

const useStyles = makeStyles()((theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    height: '100%',
    minHeight: '100vh',
    flexDirection: 'column',
  },
}));

function VerificationView() {
  const { classes } = useStyles();
  const history = useNavigate();
  const { id, token } = useParams();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const [data, setData] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const isMountedRef = useIsMountedRef();

  const handleSubmitSuccess = () => {
    history('/login');
  };

  const resendVerificationEmail = () => {
    history('/login');
  };

  const getData = useCallback(() => {
    axios
      .get('/api/verification', {
        params: {
          userId: id,
          token,
        },
      })
      .then((response) => {
        if (isMountedRef.current) {
          setData(response.data);
          setLoaded(true);
        }
      });
  }, [isMountedRef, id, token]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className={classes.root}>
      {!loaded ? (
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          style={{ height: window.innerHeight / 1.1 }}
        >
          <CircularProgress style={{ marginTop: window.innerHeight / 8 }} />
        </Grid>
      ) : data ? (
        <Page
          className={classes.root}
          title={data.emailConfirmed ? 'Reset Password' : 'Verify Account'}
        >
          <Container maxWidth="sm">
            <Box mb={5} display="flex" alignItems="center">
              <RouterLink to="/">
                <Logo />
              </RouterLink>
              <Button
                component={RouterLink}
                to="/"
                className={classes.backButton}
                color="secondary"
              >
                Back to home
              </Button>
            </Box>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h2" color="textPrimary">
                  {data.emailConfirmed ? 'Reset Password' : 'Verify Account'}
                </Typography>
                <Box mt={3}>
                  <RegisterForm
                    onSubmitSuccess={handleSubmitSuccess}
                    data={data}
                  />
                </Box>
                <Box my={2}>
                  <Divider />
                </Box>
                <Link
                  component={RouterLink}
                  to="/login"
                  variant="body2"
                  color="textSecondary"
                >
                  Have an account?
                </Link>
              </CardContent>
            </Card>
          </Container>
        </Page>
      ) : (
        <Page title="Verification Invalid or Expired">
          <Container maxWidth="lg" style={{ marginTop: 100 }}>
            <Typography
              align="center"
              variant={mobileDevice ? 'h4' : 'h1'}
              color="secondary"
            >
              Verification Failed
            </Typography>
            <Typography align="center" variant="subtitle2" color="secondary">
              Verification token may be invalid or expired.
            </Typography>
            <Box mt={6} display="flex" justifyContent="center">
              <Button
                color="secondary"
                component={RouterLink}
                to="/"
                variant="outlined"
                style={{
                  marginBottom: window.innerHeight - 310,
                  marginRight: 10,
                }}
              >
                Back to home
              </Button>

              <Button
                color="secondary"
                variant="outlined"
                onClick={resendVerificationEmail}
                style={{ marginBottom: window.innerHeight - 310 }}
              >
                Resend Verification Email
              </Button>
            </Box>
          </Container>
        </Page>
      )}
    </div>
  );
}

export default VerificationView;
