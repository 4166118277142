/* eslint-disable no-param-reassign */
import produce from 'immer';
import { GET_EVENTS, RESET } from 'src/actions/truckEventActions';

const initialState = {
  events: null
};

const TruckEventReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case GET_EVENTS: {
      const events = action.payload;

      return produce(state, (draft) => {
        draft.events = events;
      });
    }

    case RESET:
      return initialState; // Always return the initial state

    default: {
      return state;
    }
  }
};

export default TruckEventReducer;
