import React, { useState } from 'react';
import {
  Button,
  Grid,
  Dialog,
  DialogContent,
  CircularProgress,
  CardMedia,
  DialogActions,
  DialogTitle,
  Backdrop,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { Printer as PrintIcon } from 'react-feather';
import axios from 'src/utils/axios';
import Download from 'src/components/Download';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import useWindowSize from 'src/hooks/useWindowSize';

const useStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.tooltip + 1,
    color: '#fff',
  },
}));

function PrintToolbar({ reportLocation, reportName, data, ...rest }) {
  const { classes } = useStyles();
  const [selectedAttachment, setSelectedAttachment] = useState(null);
  const [previewingAttachment, setPreviewingAttachment] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [windowWidth, windowHeight] = useWindowSize();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const handlePrintMenuClose = () => {
    setAnchorEl(null);
  };

  const downloadAttachment = (file, filename) => {
    setPreviewingAttachment(false);
    setBackdropOpen(false);
    Download(file, filename);
  };

  const Print = (format) => {
    handlePrintMenuClose();

    setBackdropOpen(true);

    axios
      .post(
        `/api/${reportLocation}/${reportName}/${format}`,
        JSON.stringify(data)
      )
      .then((response) => {
        setBackdropOpen(false);
        setSelectedAttachment(response.data);

        if (format === 'PDF') {
          if (mobileDevice) {
            downloadAttachment(response.data, `${reportName}.pdf`);
          } else {
            setPreviewingAttachment(true);
          }
        } else {
          downloadAttachment(
            response.data,
            reportName + (format === 'Excel' ? '.xlsx' : '.csv')
          );
        }
      })
      .catch(() => {
        setBackdropOpen(false);
        setPreviewingAttachment(false);
      });
  };

  const handlePreviewClose = () => {
    setPreviewingAttachment(false);
  };

  return (
    <div {...rest}>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog
        open={previewingAttachment}
        onClose={handlePreviewClose}
        aria-labelledby="previewDialog"
        maxWidth={false}
      >
        <DialogTitle id="previewDialog">Preview Attachment</DialogTitle>
        <DialogContent>
          {selectedAttachment === null ? (
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <CircularProgress />
            </Grid>
          ) : (
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="stretch"
            >
              <CardMedia style={{ width: windowWidth * 0.6 }}>
                <object
                  aria-labelledby="attachmentFile"
                  data={selectedAttachment}
                  type="application/pdf"
                  style={{ width: '100%', height: windowHeight - 200 }}
                />
              </CardMedia>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              downloadAttachment(selectedAttachment, `${reportName}.pdf`)
            }
            color="secondary"
            variant="contained"
          >
            Download
          </Button>
          <Button onClick={handlePreviewClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="flex-start"
      >
        <Button
          variant="contained"
          color="secondary"
          endIcon={<PrintIcon />}
          style={{ marginRight: 10 }}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          Print
        </Button>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handlePrintMenuClose}
        >
          <MenuItem style={{ width: 140 }} onClick={() => Print('PDF')}>
            PDF
          </MenuItem>
          <MenuItem onClick={() => Print('Excel')}>Excel</MenuItem>
          <MenuItem onClick={() => Print('CSV')}>CSV</MenuItem>
        </Menu>
      </Grid>
    </div>
  );
}

PrintToolbar.propTypes = {
  reportLocation: PropTypes.string.isRequired,
  reportName: PropTypes.string.isRequired,
  data: PropTypes.object,
};

export default PrintToolbar;
