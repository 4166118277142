import axios from 'src/utils/axios';

export const GET_EVENTS = '@truckEvent/get-events';
export const RESET = '@truckEvent/reset';

export function getEvents(truck) {
  const request = axios.get(`/api/truckEvent/${truck}`);

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: GET_EVENTS,
        payload: response.data
      })
    );
  };
}

export function reset() {
  return (dispatch) => {
    dispatch({
      type: RESET,
      payload: null
    });
  };
}
